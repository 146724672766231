import React from 'react';

import Heading from '@components/global/Heading';

import clsx from 'clsx';

import styles from './Article.module.css';

interface ParagraphProps {
    className?: string;
    variant?: 'subheader';
}

export function Paragraph({ children, variant, className }: React.PropsWithChildren<ParagraphProps>): JSX.Element {
    return <p className={clsx(styles.paragraph, variant && styles[variant], className)}>{children}</p>;
}

interface ListIntroProps {
    className?: string;
    children: JSX.Element;
}

export function ListIntro({ children, className }: ListIntroProps): JSX.Element {
    return <p className={clsx(styles.intro, className)}>{children}</p>;
}

interface ListItemProps {
    className?: string;
}

export function ListItem({ children, className }: React.PropsWithChildren<ListItemProps>): JSX.Element {
    return <li className={className}>{children}</li>;
}

interface ListProps {
    className?: string;
    variant?: 'icons';
}

export function List({ children, variant, className }: React.PropsWithChildren<ListProps>): JSX.Element {
    return <ul className={clsx(styles.list, variant && styles[variant], className)}>{children}</ul>;
}

interface ArticleProps {
    title?: JSX.Element | string;
    variant?: 'h2' | 'h3';
    preheader?: JSX.Element | string;
    align?: 'left' | 'center' | 'right';
    link?: JSX.Element;
    className?: string;
    articleContent?: JSX.Element;
}

function Article({ title, variant = 'h2', preheader, articleContent, link, align, className }: ArticleProps): JSX.Element {
    return (
        <article className={clsx(styles.desc, align ? styles[align] : '', className)}>
            {preheader ? <span className={styles.preheader}>{preheader}</span> : null}
            {title ? <Heading variant={variant}>{title}</Heading> : null}
            {articleContent}
            {link ? <div className={styles.link}>{link}</div> : null}
        </article>
    );
}

export default Article;
