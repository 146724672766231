import React from 'react';

import clsx from 'clsx';

import styles from './BlueArticle.module.css';

interface BlueArticleProps {
    title?: string;
    className?: string;
}

function BlueArticle({ title, children, className }: React.PropsWithChildren<BlueArticleProps>): JSX.Element {
    return (
        <div className={clsx(styles.blue, className)}>
            {title && <h3>{title}</h3>}
            {children}
        </div>
    );
}

export default BlueArticle;
