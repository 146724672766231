import React from 'react';

import styles from './FaqQuestionAndAnswer.module.css';
import { ReactComponent as SVGIcon } from './arrow.svg';

interface FaqQuestionAndAnswerProps {
    question: string;
    answer: string;
    open?: boolean;
}

export default function FaqQuestionAndAnswer({ question, answer, open }: FaqQuestionAndAnswerProps): JSX.Element {
    const openElement = open ? true : false;
    return (
        <details
            className={styles.details}
            open={openElement}
        >
            <summary className={styles.summary}>
                {question}
                <SVGIcon />
            </summary>
            <article
                className={styles.article}
                dangerouslySetInnerHTML={{
                    __html: answer,
                }}
            />
        </details>
    );
}
