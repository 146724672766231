import React from 'react';

import clsx from 'clsx';

import styles from './FeatureInfo.module.css';
import { SVGBulb } from './assets/Icons';

interface FeatureInfoProps {
    text: JSX.Element | string;
    icon?: JSX.Element;
    className?: string;
}

export default function FeatureInfo({ text, icon, className }: FeatureInfoProps): JSX.Element {
    return (
        <div className={clsx(styles.info, className)}>
            <span className={styles.icon}>{icon ? icon : <SVGBulb />}</span>
            <div className={styles.text}>{text}</div>
        </div>
    );
}
