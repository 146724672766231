import React from 'react';

import clsx from 'clsx';

import styles from './Feature.module.css';

interface FeatureProps {
    alignVertical?: 'start' | 'middle' | 'end';
    article: JSX.Element;
    picture: JSX.Element;
    className?: string;
    columnReverse?: boolean;
    anchor?: string;
}

export default function Feature({ article, picture, alignVertical = 'start', columnReverse = false, className, anchor }: FeatureProps): JSX.Element {
    return (
        <div
            id={anchor}
            className={clsx(styles.feature, className)}
        >
            <div className={clsx(styles.split, columnReverse && styles.reverse)}>
                <div className={clsx(styles.details, styles[alignVertical])}>{article}</div>
                <div className={clsx(styles.picture, styles[alignVertical])}>{picture}</div>
            </div>
        </div>
    );
}
