import React from 'react';

import clsx from 'clsx';

import styles from './Label.module.css';

interface LabelProps {
    text: string;
    color?: 'blue' | 'yellow' | 'orange' | 'navy' | 'green';
    className?: string;
}

export default function Label({ text, color = 'blue', className }: LabelProps): JSX.Element {
    return (
        <div className={clsx(styles.label, className)}>
            <span className={clsx(styles[color], styles.span)}>{text}</span>
        </div>
    );
}
