import dynamic from 'next/dynamic';

import type { FeatureTooltipProps } from './Tooltip';

const Tooltip = dynamic(() => import('./Tooltip'), {
    ssr: false,
});

export default function FeatureTooltip(props: FeatureTooltipProps): JSX.Element | null {
    return <Tooltip {...props}></Tooltip>;
}
