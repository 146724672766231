import React, { Children } from 'react';

import clsx from 'clsx';

import styles from './Manual.module.css';

interface StepProps {
    className?: string;
    icon?: JSX.Element;
    title: string | JSX.Element;
    texts: JSX.Element;
    picture: JSX.Element;
}

export function Step({ className, title, icon, texts, picture }: StepProps): JSX.Element {
    return (
        <article className={clsx(styles.wrap, icon ? styles.icons : styles.bullets, className)}>
            <div className={styles.texts}>
                <h3 className={styles.title}>
                    {icon && <>{icon}</>}
                    {title}
                </h3>
                {texts}
            </div>
            <figure className={styles.picture}>{picture}</figure>
        </article>
    );
}

interface ManualProps {
    className?: string;
    align?: 'left' | 'right';
}
function Manual({ className, children, align = 'right' }: React.PropsWithChildren<ManualProps>) {
    const arrayChildren = Children.toArray(children);
    return (
        <ul className={clsx(styles.manual, styles[align], className)}>
            {arrayChildren.map((Child, i) => {
                return (
                    <li
                        className={styles.step}
                        key={i}
                    >
                        {Child}
                    </li>
                );
            })}
        </ul>
    );
}

export default Manual;
